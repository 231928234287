import React from 'react';
import { useState } from 'react';
import styles from './vibracelet.module.css';
import img from './assets/finalphoto.jpg';
import back_white from '../../assets/back_white.png';
import close from '../../assets/close_white.png';
import vibraceleticon from './assets/vibracelet_icon.svg';
import arrow from './assets/Arrow 1.png';
import museum from "./assets/museum.jpeg";
import demoday from './assets/demoday.jpeg';
import insideVibracelet from './assets/insidefinalproto.jpg';
import proto1 from './assets/1stproto.jpg';
import proto2 from './assets/3rdproto.jpg';
import userEvaluation from './assets/userEvaluation.JPG';
import paperPDF from './assets/Vibracelet_TeamE_2024.pdf';

const title = "Vibracelet";
const imgAltText = "Vibracelet";
const tags = ["Hardware Design", "HCI", "Wearable Tech"];
const hiddenClassName = styles["expanded-project-hidden"];

function TemplatePage(props) {
    const [isClosing, setIsClosing] = useState(false);
    const [currentSection, setCurrentSection] = useState(1);
    const [isVibrating, setIsVibrating] = useState(false);

    React.useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key.toLowerCase() === 'v') {
                setIsVibrating(prev => !prev);
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const scrollToPage = function(id) {
        var currentPage = parseInt(id);
        setCurrentSection(currentPage);
        var myElement = document.getElementById(`vibracelet-section-${currentPage}`);
        var topPos = myElement.offsetTop;
        document.getElementById('vibracelet-slider').scrollTo({
            top: topPos,
            behavior: 'smooth'
        });
    }

    const modualiseClassNames = function (classNames) {
        let str = "";
        for (let i = 0; i < classNames.length; i++) {
            str += styles[classNames[i]]; 
            str += " ";
        }
        return str;
    };

    const handleClose = (e) => {
        e.stopPropagation();
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            props.closeFunction();
        }, 200);
    };

    return (
        <div className={`${styles['expanded-project']} 
            ${props.hidden ? styles["hidden-project"] : ""} 
            ${isClosing ? styles["closing"] : ""}
            ${props.containerClasses ? modualiseClassNames(props.containerClasses) : ""}`}>
            {!props.hidden && (
                <>
                    <div className={modualiseClassNames(["top", "expanded-project-top"])}>
                        <button className={styles["back_button"]}
                            onClick={handleClose}
                        >
                            <img className="close-button" src={back_white} alt="close window icon" />
                        </button>

                        <div className={styles["tags"]}>
                            {tags.map((tag, index) => (
                                <span key={index} className={styles["tag"]}>{tag}</span>
                            ))}
                        </div>

                        <button className={styles["close_button"]}
                            onClick={() => document.getElementById("projects-page").classList.toggle("hidden")}
                        >
                            <img className="close-button" src={close} alt="close window icon" />
                        </button>
                    </div>

                    <div className={styles["expanded-project-body"]}>
                        <div id="vibracelet-slider" className={styles["slider"]}>
                            <section id="vibracelet-section-1">
                                <div className={styles["progress-dots"]}>
                                    <div onClick={() => scrollToPage(1)} className={styles["progress-dot-1"] + " " + styles["progress-dot"] + " " + styles["current"]}></div>
                                    <div onClick={() => scrollToPage(2)} className={styles["progress-dot-2"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(3)} className={styles["progress-dot-3"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(4)} className={styles["progress-dot-4"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(5)} className={styles["progress-dot-5"] + " " + styles["progress-dot"]}></div>
                                </div>
                                <div className={styles.firstContainer}>
                                    <div className={styles.topLine}>               
                                        <div className={styles.title + " " + (isVibrating ? styles.buzz : "")}>Vibracelet</div>
                                        <img className={styles["vibracelet-icon"]} src={vibraceleticon} alt="Graphic Icon of Vibracelet" />
                                    </div>
                                    <div className={styles.subHeading}>
                                        A dual-bracelet device designed to discreetly communicate text information using vibrotactile feedback.
                                    </div>
                                    <div className={styles.paperLink}>
                                        <button 
                                            onClick={() => window.open(paperPDF, '_blank')}
                                            className={styles.paperLinkButton}
                                        >
                                            An ACM Research Paper
                                        </button>
                                    </div>
                                        
                               
                                    <a onClick={() => scrollToPage(2)} className={styles.arrowWrapper}>
                                        <img src={arrow} alt="Arrow" />
                                    </a>
                                </div>
                            </section>
                            <section id="vibracelet-section-2">
                                <div className={styles["progress-dots"]}>
                                    <div onClick={() => scrollToPage(1)} className={styles["progress-dot-1"] + " " + styles["progress-dot"] }></div>
                                    <div onClick={() => scrollToPage(2)} className={styles["progress-dot-2"] + " " + styles["progress-dot"] + " " + styles["current"]}></div>
                                    <div onClick={() => scrollToPage(3)} className={styles["progress-dot-3"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(4)} className={styles["progress-dot-4"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(5)} className={styles["progress-dot-5"] + " " + styles["progress-dot"]}></div>                
                                </div>
                                <div className={styles.secondContainer}>
                                    <img 
                                        src={museum} 
                                        alt="A visitor experiencing art at the Victoria & Albert Museum while wearing Vibracelet devices on both wrists, demonstrating the device in a real-world cultural setting" 
                                        className={styles.topLeftImage}
                                    />
                                    <div className={styles.topRightText}>
                                        <h2>The Task</h2>
                                        <p>Design an <strong>interactive device</strong> to enhance the experience of museum visitors.</p>
                                    </div>
                                    <div className={styles.bottomLeftText}>
                                        <h2>Problem Statement</h2>
                                        <p>How can a <strong>dual-bracelet wearable device</strong> using <strong>vibrotactile feedback</strong> enhance <strong>visitor engagement</strong> in museums by discreetly delivering information without relying on <strong>audio or visual channels</strong>?</p>
                                    </div>
                                    <img 
                                        src={demoday}
                                        alt="A researcher demonstrating Vibracelet's custom software interface during a demo day, showing how text messages are converted into vibration patterns for the wearable devices" 
                                        className={styles.bottomRightImage}
                                    />
                                </div>
                                    

                                    




                            </section>
                                <section id="vibracelet-section-3">
                                <div className={styles["progress-dots"]}>
                                    <div onClick={() => scrollToPage(1)} className={styles["progress-dot-1"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(2)} className={styles["progress-dot-2"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(3)} className={styles["progress-dot-3"] + " " + styles["progress-dot"] + " " + styles["current"]}></div>
                                    <div onClick={() => scrollToPage(4)} className={styles["progress-dot-4"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(5)} className={styles["progress-dot-5"] + " " + styles["progress-dot"]}></div>
                                </div>
                                <div className={styles.researchContainer}>
                                    <h2 className={styles.researchHeading}>Skills Gained</h2>
                                    <div className={styles.skillsGrid}>
                                        <div className={styles.skillCard}>
                                            <div className={styles.skillIcon}>⚙️</div>
                                            <h3>Hardware Prototyping</h3>
                                            <p>Developed physical iterations with increasing fidelity, learning to work with electronics, materials, and manufacturing constraints</p>
                                        </div>
                                        <div className={styles.skillCard}>
                                            <div className={styles.skillIcon}>📊</div>
                                            <h3>Data-Driven Design</h3>
                                            <p>Used quantitative user testing data to refine vibration patterns, motor placement, and feedback mechanisms</p>
                                        </div>
                                        <div className={styles.skillCard}>
                                            <div className={styles.skillIcon}>🧪</div>
                                            <h3>User Testing</h3>
                                            <p>Conducted rigorous user evaluations to measure accuracy, comfort, and learning curves across different user groups</p>
                                        </div>
                                    </div>
                                    <div className={styles.prototypeBox}>
                                        <div className={styles.prototypeContent}>
                                            <div className={styles.prototypeLeft}>
                                                <h2 className={styles.prototypeHeading}>Prototyping</h2>
                                                <h4 className={styles.prototypeSubheading}>Final Design</h4>
                                                <ul className={styles.prototypeList}>
                                                    <li><strong>User-Centred Hardware Design</strong>: Dual elastic bracelets with integrated vibration motors provide a comfortable, adjustable fit and deliver precise haptic feedback inspired by Braille.</li>
                                                    <li><strong>Advanced Control System</strong>: Powered by an Arduino Micro, the device supports customisable vibration patterns and ensures consistent motor calibration.</li>
                                                    <li><strong>Interactive Software Interface</strong>: A web-based GUI enables pattern input, custom design creation, learning modes, and detailed performance tracking.</li>
                                                </ul>
                                            </div>
                                            <div className={styles.prototypeImages}>
                                                <img src={img} alt="First prototype image" className={styles.prototypeImage1} />
                                                <img src={insideVibracelet} alt="Second prototype image" className={styles.prototypeImage2} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>





                            <section id="vibracelet-section-4">
                                <div className={styles["progress-dots"]}>
                                    <div onClick={() => scrollToPage(1)} className={styles["progress-dot-1"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(2)} className={styles["progress-dot-2"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(3)} className={styles["progress-dot-3"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(4)} className={styles["progress-dot-4"] + " " + styles["progress-dot"] + " " + styles["current"]}></div>
                                    <div onClick={() => scrollToPage(5)} className={styles["progress-dot-5"] + " " + styles["progress-dot"]}></div>
                                </div>
                                <div className={styles.earlyPrototypesContainer}>
                                    <div className={styles.earlyPrototypesBox}>
                                        <h4 className={styles.prototypeSubheading}>Early Prototypes</h4>
                                        <div className={styles.prototypeColumns}>
                                            <img src={proto1} alt="Early prototype version" className={styles.prototypeColumnImage} />
                                            <p className={styles.prototypeColumnText}>
                                                Every project begins with a rough prototype, and ViBracelet was no exception. The initial design featured 3D-printed nodes housing haptic motors, loosely secured with elastic loops, and connected to an Arduino via a tangle of spider-like cables—giving it an unintentionally creature-like appearance.
                                            </p>
                                            <p className={styles.prototypeColumnText}>
                                                Subsequent prototypes improved usability by distributing vibrations across both wrists, mimicking Braille's two-column layout. This approach not only reduced the cognitive load on a single wrist but also enhanced the design's simplicity, durability, and ease of repair.
                                            </p>
                                            <img src={proto2} alt="Improved prototype version" className={styles.prototypeColumnImage} />
                                        </div>
                                    </div>
                                    
                                    <div className={styles.evaluationSection}>
                                        <div className={styles.evaluationLeft}>
                                            <h2 className={styles.evaluationHeading}>User Evaluation</h2>
                                            <div className={styles.evaluationText}>
                                                <p>Evaluated by 14 participants through multiple-choice tests designed to measure their ability to correctly identify characters transmitted to Vibracelet. The study achieved an average accuracy rate of 85.7%, with expert users achieving up to 98.3% accuracy.</p>
                                                <p>Participants praised its comfort and discreetness, though challenges such as the distinguishability of certain patterns and external vibration interference were noted. NASA TLX surveys indicated low physical demand and frustration, with some mental fatigue reported, highlighting opportunities for refinement in future iterations.</p>
                                            </div>
                                        </div>
                                        <div className={styles.evaluationRight}>
                                            <img 
                                                src={userEvaluation} 
                                                alt="User evaluation session" 
                                                className={styles.evaluationImage}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                                <section id="vibracelet-section-5">
                                <div className={styles["progress-dots"]}>
                                    <div onClick={() => scrollToPage(1)} className={styles["progress-dot-1"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(2)} className={styles["progress-dot-2"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(3)} className={styles["progress-dot-3"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(4)} className={styles["progress-dot-4"] + " " + styles["progress-dot"]}></div>
                                    <div onClick={() => scrollToPage(5)} className={styles["progress-dot-5"] + " " + styles["progress-dot"] + " " + styles["current"]}></div>
                                </div>
                                <div className={styles.reflectionContainer}>
                                    <div className={styles.reflectionLeft}>
                                        <h2 className={styles.reflectionHeading}>Reflections</h2>
                                        <div className={styles.reflectionText}>
                                            <p>Through this project, I gained invaluable experience in several key areas:</p>
                                            <ul className={styles.reflectionList}>
                                                <li><strong>Innovative Design:</strong> The ViBracelet project explored vibrotactile communication through a dual-bracelet system inspired by Braille, pushing the boundaries of discreet, non-verbal interaction.</li>
                                                <li><strong>User-Centred Innovation:</strong> User feedback on comfort, intuitiveness, and pattern recognition directly informed design improvements, highlighting the importance of usability in real-world contexts.</li>
                                                <li><strong>Iterative Prototyping:</strong> Each prototype revealed key insights, from enhancing vibration distinguishability to improving comfort and durability, demonstrating the value of iterative refinement.</li>
                                                <li><strong>Challenges and Lessons:</strong> High accuracy rates were achieved, but challenges like external vibrations and mental fatigue emphasised areas for further refinement and real-world adaptability.</li>
                                                <li><strong>Future Potential:</strong> The project showcased the promise of wearable technology for accessibility and discreet communication, while underscoring the importance of ethical and inclusive design.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={styles.reflectionRight}>
                                        <h2 className={styles.reflectionHeading}>Full Paper</h2>
                                        <button 
                                            onClick={() => window.open(paperPDF, '_blank')}
                                            className={styles.openPdfButton}
                                        >
                                            Open Full Paper in New Tab
                                        </button>
                                        <div className={styles.pdfContainer}>
                                            <iframe 
                                                src={`${paperPDF}#view=FitH&zoom=100&toolbar=0&navpanes=0`}
                                                className={styles.pdfEmbed}
                                                title="Vibracelet Research Paper"
                                                type="application/pdf"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default { title, tags, img, imgAltText, TemplatePage, hiddenClassName };
