import React, { useState, useRef } from 'react';
import { Gradient } from 'whatamesh';
import { useEffect } from 'react';
import '../App.css';
import '../styles.css';
import styles from './Temperature.module.css';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
);

function Temperature() {
    const [temperatureData, setTemperatureData] = useState([]);
    const [bmeData, setBmeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sensorId, setSensorId] = useState('');

    // Function to update the temperature gradient based on actual min/max values
    const updateTemperatureGradient = () => {
        if (temperatureData.length === 0 && bmeData.length === 0) return;
        
        // Get min and max temperatures from both sensors
        let minTemp = Infinity;
        let maxTemp = -Infinity;
        
        if (temperatureData.length > 0) {
            const dht11Min = Math.min(...temperatureData.map(reading => reading.temperature));
            const dht11Max = Math.max(...temperatureData.map(reading => reading.temperature));
            minTemp = Math.min(minTemp, dht11Min);
            maxTemp = Math.max(maxTemp, dht11Max);
        }
        
        if (bmeData.length > 0) {
            const bmeMin = Math.min(...bmeData.map(reading => reading.temperature));
            const bmeMax = Math.max(...bmeData.map(reading => reading.temperature));
            minTemp = Math.min(minTemp, bmeMin);
            maxTemp = Math.max(maxTemp, bmeMax);
        }
        
        // Round to nearest whole number and add a small buffer
        minTemp = Math.floor(minTemp) - 1;
        maxTemp = Math.ceil(maxTemp) + 1;
        
        // Define the full temperature scale range
        const fullScaleMin = -30; // Very cold
        const fullScaleMax = 50;  // Very hot
        
        // Map our temperature range to the full gradient scale (0-100%)
        // This maps -30°C to 0% and 50°C to 100%
        const startPercent = Math.max(0, Math.min(100, ((minTemp - fullScaleMin) / (fullScaleMax - fullScaleMin)) * 100));
        const endPercent = Math.max(0, Math.min(100, ((maxTemp - fullScaleMin) / (fullScaleMax - fullScaleMin)) * 100));
        
        // Set CSS variables for the gradient
        document.documentElement.style.setProperty('--temp-min', minTemp);
        document.documentElement.style.setProperty('--temp-max', maxTemp);
        document.documentElement.style.setProperty('--temp-range-start', startPercent);
        document.documentElement.style.setProperty('--temp-range-end', endPercent);
        
        console.log(`Temperature range: ${minTemp}°C to ${maxTemp}°C`);
        console.log(`Gradient range: ${startPercent.toFixed(1)} to ${endPercent.toFixed(1)}`);
    };

    useEffect(() => {
        console.log('Initializing gradient...');
        try {
            const gradient = new Gradient();
            gradient.initGradient('#gradient-canvas');
            console.log('Gradient initialized successfully');
        } catch (error) {
            console.error('Error initializing gradient:', error);
        }

        // Set viewport height
        document.documentElement.setAttribute("style", `--viewport-height: ${(window.innerHeight).toString()}px`);
    }, []);

    useEffect(() => {
        const fetchTemperature = async () => {
            try {
                // Fetch both sensors
                const [liamsResponse, bmeResponse] = await Promise.all([
                    fetch('https://ubxej29ux5.execute-api.eu-west-2.amazonaws.com/prod/temperature?sensor_id=liams_room_uni'),
                    fetch('https://ubxej29ux5.execute-api.eu-west-2.amazonaws.com/prod/temperature?sensor_id=liams_room_in')
                ]);
                
                if (!liamsResponse.ok || !bmeResponse.ok) {
                    const liamsError = await liamsResponse.json();
                    const bmeError = await bmeResponse.json();
                    throw new Error(`API Error - DHT11: ${JSON.stringify(liamsError)}, BME: ${JSON.stringify(bmeError)}`);
                }
                
                const liamsData = await liamsResponse.json();
                const bmeData = await bmeResponse.json();
                
                console.log('Received liams data:', liamsData);
                console.log('Received BME data:', bmeData);
                
                setTemperatureData(liamsData);
                setBmeData(bmeData);
                
                if (liamsData.length > 0) {
                    const formattedSensorId = liamsData[0].sensor_id
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                    setSensorId(formattedSensorId);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error details:', error);
                setError(error.message);
                setLoading(false);
            }
        };

        fetchTemperature();
        const interval = setInterval(fetchTemperature, 60000);
        return () => clearInterval(interval);
    }, []);

    // Update temperature gradient when data changes
    useEffect(() => {
        updateTemperatureGradient();
    }, [temperatureData, bmeData]);

    const getChartOptions = (dataType) => ({
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 200,
        animation: {
            resize: {
                duration: 0
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#ffffff',
                    usePointStyle: true,
                    padding: 20,
                    font: {
                        family: 'BigShoulders',
                        size: 14
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    modifierKey: null,
                },
                zoom: {
                    wheel: {
                        enabled: false
                    },
                    pinch: {
                        enabled: false
                    },
                    drag: {
                        enabled: false
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    stepSize: 2,
                    displayFormats: {
                        hour: 'HH:mm'
                    },
                    tooltipFormat: 'PPp'
                },
                ticks: {
                    color: 'rgb(38, 38, 38, 0.6)',
                    source: 'data',
                    autoSkip: true,
                    maxTicksLimit: 12,
                    callback: function(value, index, values) {
                        const date = new Date(value);
                        const hour = date.getHours();
                        
                        if (hour === 12) {
                            return [
                                date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
                                date.toLocaleDateString([], {month: 'short', day: 'numeric'})
                            ];
                        }
                        return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
                    }
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.2)'
                }
            },
            y: {
                beginAtZero: false,
                ticks: {
                    color: 'rgb(38, 38, 38, 0.6)',
                    callback: (value) => {
                        switch(dataType) {
                            case 'temperature':
                                return `${value}°C`;
                            case 'humidity':
                            case 'air_quality':
                                return `${value}%`;
                            case 'pressure':
                                return `${Math.round(value)} hPa`;
                            case 'gas_resistance':
                                return `${value} Ω`;
                            default:
                                return value;
                        }
                    }
                },
                grid: {
                    color: 'rgba(255, 255, 255, 0.1)'
                }
            }
        }
    });

    const temperatureChartData = {
        labels: temperatureData.map(reading => new Date(reading.timestamp)),
        datasets: [
            {
                label: 'DHT11',
                data: temperatureData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.temperature
                })),
                borderColor: 'rgb(252, 124, 64)',
                backgroundColor: 'rgba(252, 124, 64, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            },
            {
                label: 'BME680',
                data: bmeData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.temperature
                })),
                borderColor: 'rgb(71, 157, 249)',
                backgroundColor: 'rgba(71, 157, 249, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            }
        ],
    };

    const humidityChartData = {
        labels: temperatureData.map(reading => new Date(reading.timestamp)),
        datasets: [
            {
                label: 'DHT11',
                data: temperatureData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.humidity
                })),
                borderColor: 'rgb(252, 124, 64)',
                backgroundColor: 'rgba(252, 124, 64, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            },
            {
                label: 'BME680',
                data: bmeData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.humidity
                })),
                borderColor: 'rgb(71, 157, 249)',
                backgroundColor: 'rgba(71, 157, 249, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            }
        ],
    };

    const pressureChartData = {
        labels: bmeData.map(reading => new Date(reading.timestamp)),
        datasets: [
            {
                label: 'BME680',
                data: bmeData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.pressure
                })),
                borderColor: 'rgb(71, 157, 249)',
                backgroundColor: 'rgba(71, 157, 249, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            }
        ],
    };

    const gasResistanceChartData = {
        labels: bmeData.map(reading => new Date(reading.timestamp)),
        datasets: [
            {
                label: 'BME680',
                data: bmeData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.gas_resistance
                })),
                borderColor: 'rgb(71, 157, 249)',
                backgroundColor: 'rgba(71, 157, 249, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            }
        ],
    };

    const airQualityChartData = {
        labels: bmeData.map(reading => new Date(reading.timestamp)),
        datasets: [
            {
                label: 'BME680',
                data: bmeData.map(reading => ({
                    x: new Date(reading.timestamp),
                    y: reading.air_quality
                })),
                borderColor: 'rgb(71, 157, 249)',
                backgroundColor: 'rgba(71, 157, 249, 0.5)',
                borderWidth: 1,
                pointRadius: 3,
                tension: 0.4,
            }
        ],
    };

    // Update helper functions to handle both sensors
    const getLatestReading = (data1, data2, field) => {
        if (data1 === null) {
            // Only using data2 (BME sensor)
            return (!data2 || data2.length === 0) ? '—' : data2[data2.length - 1][field].toFixed(1);
        } else {
            // Using both sensors
            const reading1 = (!data1 || data1.length === 0) ? '—' : data1[data1.length - 1][field].toFixed(1);
            const reading2 = (!data2 || data2.length === 0) ? '—' : data2[data2.length - 1][field].toFixed(1);
        return `${reading1}/${reading2}`;
        }
    };

    const getAverageReading = (data1, data2, field) => {
        if (data1 === null) {
            // Only using data2 (BME sensor)
            return (!data2 || data2.length === 0) ? '—' : 
                (data2.reduce((acc, curr) => acc + (curr[field] || 0), 0) / data2.length).toFixed(1);
        } else {
            // Using both sensors
        const avg1 = (!data1 || data1.length === 0) ? '—' : 
                (data1.reduce((acc, curr) => acc + (curr[field] || 0), 0) / data1.length).toFixed(1);
        const avg2 = (!data2 || data2.length === 0) ? '—' : 
                (data2.reduce((acc, curr) => acc + (curr[field] || 0), 0) / data2.length).toFixed(1);
        return `${avg1}/${avg2}`;
        }
    };

    const getMaxReading = (data1, data2, field) => {
        if (data1 === null) {
            // Only using data2 (BME sensor)
            return (!data2 || data2.length === 0) ? '—' : 
                Math.max(...data2.map(reading => reading[field] || 0)).toFixed(1);
        } else {
            // Using both sensors
        const max1 = (!data1 || data1.length === 0) ? '—' : 
                Math.max(...data1.map(reading => reading[field] || 0)).toFixed(1);
        const max2 = (!data2 || data2.length === 0) ? '—' : 
                Math.max(...data2.map(reading => reading[field] || 0)).toFixed(1);
        return `${max1}/${max2}`;
        }
    };

    const getMinReading = (data1, data2, field) => {
        if (data1 === null) {
            // Only using data2 (BME sensor)
            return (!data2 || data2.length === 0) ? '—' : 
                Math.min(...data2.map(reading => reading[field] || Infinity)).toFixed(1);
        } else {
            // Using both sensors
        const min1 = (!data1 || data1.length === 0) ? '—' : 
                Math.min(...data1.map(reading => reading[field] || Infinity)).toFixed(1);
        const min2 = (!data2 || data2.length === 0) ? '—' : 
                Math.min(...data2.map(reading => reading[field] || Infinity)).toFixed(1);
        return `${min1}/${min2}`;
        }
    };

    // Add these helper functions after the existing getMinReading function
    const getProgressWidth = (current, min, max) => {
        if (current === '—' || min === '—' || max === '—') return 50; // Default to middle if data is missing
        
        // Handle cases where we have multiple sensors
        let currentVal, minVal, maxVal;
        
        if (typeof current === 'string' && current.includes('/')) {
            // Use the second value (BME sensor) for consistency
            currentVal = parseFloat(current.split('/')[1]);
            minVal = parseFloat(min.split('/')[1]);
            maxVal = parseFloat(max.split('/')[1]);
        } else {
            currentVal = parseFloat(current);
            minVal = parseFloat(min);
            maxVal = parseFloat(max);
        }
        
        // Check for NaN or invalid values
        if (isNaN(currentVal) || isNaN(minVal) || isNaN(maxVal)) return 50;
        
        // Calculate percentage (0-100)
        const range = maxVal - minVal;
        if (range <= 0) return 50; // Default to 50% if range is invalid
        
        const percentage = ((currentVal - minVal) / range) * 100;
        return Math.max(0, Math.min(100, percentage)); // Clamp between 0-100
    };

    // Add a specialized function for pressure, air quality, and gas resistance
    const getFilteredProgressWidth = (data, field, currentValue, isAverage = false) => {
        if (!data || data.length === 0) return 50;
        
        // Filter out NaN values
        const filteredData = data.filter(reading => reading[field] !== undefined && !isNaN(reading[field]));
        
        if (filteredData.length === 0) return 50;
        
        // Get min and max from filtered data
        const minVal = Math.min(...filteredData.map(reading => reading[field]));
        const maxVal = Math.max(...filteredData.map(reading => reading[field]));
        
        // Get current value
        let currentVal;
        if (isAverage) {
            // Calculate average from filtered data
            currentVal = filteredData.reduce((acc, curr) => acc + curr[field], 0) / filteredData.length;
        } else {
            // Use the latest reading
            currentVal = currentValue;
        }
        
        if (isNaN(currentVal)) return 50;
        
        // Calculate percentage
        const range = maxVal - minVal;
        if (range <= 0) return 50;
        
        const percentage = ((currentVal - minVal) / range) * 100;
        return Math.max(0, Math.min(100, percentage)); // Clamp between 0-100
    };

    const getReadingTrend = (data, field) => {
        if (!data || data.length < 3) return 'stable';
        
        const lastThreeReadings = data.slice(-3).map(reading => reading[field]);
        
        // Check if any of the readings are NaN
        if (lastThreeReadings.some(reading => reading === undefined || isNaN(reading))) {
            return 'stable';
        }
        
        const firstReading = lastThreeReadings[0];
        const lastReading = lastThreeReadings[2];
        
        const difference = lastReading - firstReading;
        
        if (Math.abs(difference) < 0.5) return 'stable';
        return difference > 0 ? 'rising' : 'falling';
    };

    if (loading) return (
        <div onClick={() => {}}>
            <div className="gradient-container">
                <canvas id="gradient-canvas" data-transition-in/>
            </div>
            <div className="noise"></div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.loadingContainer}>
                        <div className={styles.loadingSpinner} />
                        <h4 className={styles.loadingText}>Loading</h4>
                    </div>
                </div>
            </div>
        </div>
    );

    if (error) return (
        <div onClick={() => {}}>
            <div className="gradient-container">
                <canvas id="gradient-canvas" data-transition-in/>
            </div>
            <div className="noise"></div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.title}>Error: {error}</h1>
                </div>
            </div>
        </div>
    );

    return (
        <div onClick={() => {}}>
            <div className="gradient-container">
                <canvas id="gradient-canvas" data-transition-in/>
            </div>
            <div className="noise"></div>
            
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h1 className={styles.title}>Liam's Room</h1>
                    </div>
                    
                    <div className={styles.bentoGrid}>
                        {/* Temperature Card */}
                        <div className={styles.measurementCard}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>
                                    Temperature
                                </h2>
                            </div>
                            <div className={styles.graphSection}>
                                <div className={styles.graph}>
                                    <Line options={getChartOptions('temperature')} data={temperatureChartData} />
                                </div>
                            </div>
                            <div className={styles.statsSection}>
                                <div className={styles.statsGrid}>
                                    {/* DHT11 Temperature Stat */}
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>DHT11</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getProgressWidth(
                                                    temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].temperature.toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getProgressWidth(
                                                    temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.temperature, 0) / temperatureData.length).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].temperature.toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].temperature.toFixed(1) : '—'}°C</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.temperature, 0) / temperatureData.length).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.temperature, 0) / temperatureData.length).toFixed(1) : '—'}°C</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'}°C</span>
                                                <span>{temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.temperature)).toFixed(1) : '—'}°C</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* BME680 Temperature Stat */}
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>BME680</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getProgressWidth(
                                                    bmeData.length > 0 ? bmeData[bmeData.length - 1].temperature.toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getProgressWidth(
                                                    bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.temperature, 0) / bmeData.length).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        bmeData.length > 0 ? bmeData[bmeData.length - 1].temperature.toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{bmeData.length > 0 ? bmeData[bmeData.length - 1].temperature.toFixed(1) : '—'}°C</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.temperature, 0) / bmeData.length).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.temperature, 0) / bmeData.length).toFixed(1) : '—'}°C</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'}°C</span>
                                                <span>{bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.temperature)).toFixed(1) : '—'}°C</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Humidity Card */}
                        <div className={styles.measurementCard}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>
                                    Humidity
                                </h2>
                            </div>
                            <div className={styles.graphSection}>
                                <div className={styles.graph}>
                                    <Line options={getChartOptions('humidity')} data={humidityChartData} />
                                </div>
                            </div>
                            <div className={styles.statsSection}>
                                <div className={styles.statsGrid}>
                                    {/* DHT11 Humidity Stat */}
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>DHT11</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getProgressWidth(
                                                    temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].humidity.toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getProgressWidth(
                                                    temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.humidity, 0) / temperatureData.length).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                    temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].humidity.toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{temperatureData.length > 0 ? temperatureData[temperatureData.length - 1].humidity.toFixed(1) : '—'}%</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.humidity, 0) / temperatureData.length).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                        temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{temperatureData.length > 0 ? (temperatureData.reduce((acc, curr) => acc + curr.humidity, 0) / temperatureData.length).toFixed(1) : '—'}%</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{temperatureData.length > 0 ? Math.min(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'}%</span>
                                                <span>{temperatureData.length > 0 ? Math.max(...temperatureData.map(reading => reading.humidity)).toFixed(1) : '—'}%</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* BME680 Humidity Stat */}
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>BME680</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getProgressWidth(
                                                    bmeData.length > 0 ? bmeData[bmeData.length - 1].humidity.toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getProgressWidth(
                                                    bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.humidity, 0) / bmeData.length).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                    bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                )}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        bmeData.length > 0 ? bmeData[bmeData.length - 1].humidity.toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{bmeData.length > 0 ? bmeData[bmeData.length - 1].humidity.toFixed(1) : '—'}%</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getProgressWidth(
                                                        bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.humidity, 0) / bmeData.length).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—',
                                                        bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'
                                                    )}%` 
                                                }}>{bmeData.length > 0 ? (bmeData.reduce((acc, curr) => acc + curr.humidity, 0) / bmeData.length).toFixed(1) : '—'}%</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{bmeData.length > 0 ? Math.min(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'}%</span>
                                                <span>{bmeData.length > 0 ? Math.max(...bmeData.map(reading => reading.humidity)).toFixed(1) : '—'}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                                
                        {/* Pressure Card */}
                        <div className={styles.measurementCard}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>
                                    Pressure
                                </h2>
                            </div>
                            <div className={styles.graphSection}>
                                    <div className={styles.graph}>
                                    <Line options={getChartOptions('pressure')} data={pressureChartData} />
                                </div>
                            </div>
                            <div className={styles.statsSection}>
                                <div className={styles.statsGrid}>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>BME680</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'pressure', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].pressure) ? bmeData[bmeData.length - 1].pressure : null, false)}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'pressure', null, true)}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'pressure', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].pressure) ? bmeData[bmeData.length - 1].pressure : null, false)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].pressure) ? bmeData[bmeData.length - 1].pressure.toFixed(1) : '—'} hPa</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'pressure', null, true)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData.reduce((acc, curr) => acc + (curr.pressure || 0), 0) / bmeData.length) ? (bmeData.reduce((acc, curr) => acc + (curr.pressure || 0), 0) / bmeData.length).toFixed(1) : '—'} hPa</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{bmeData.length > 0 && !isNaN(Math.min(...bmeData.map(reading => reading.pressure || Infinity))) ? Math.min(...bmeData.filter(reading => !isNaN(reading.pressure)).map(reading => reading.pressure)).toFixed(1) : '—'} hPa</span>
                                                <span>{bmeData.length > 0 && !isNaN(Math.max(...bmeData.map(reading => reading.pressure || 0))) ? Math.max(...bmeData.filter(reading => !isNaN(reading.pressure)).map(reading => reading.pressure)).toFixed(1) : '—'} hPa</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>Trend</div>
                                        <div className={styles.statValue}>
                                            {getReadingTrend(bmeData, 'pressure') === 'rising' ? 'Rising' : 
                                             getReadingTrend(bmeData, 'pressure') === 'falling' ? 'Falling' : 'Stable'}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        {/* Air Quality Card */}
                        <div className={styles.measurementCard}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>
                                    Air Quality
                                </h2>
                            </div>
                            <div className={styles.graphSection}>
                                <div className={styles.graph}>
                                    <Line options={getChartOptions('air_quality')} data={airQualityChartData} />
                                </div>
                            </div>
                            <div className={styles.statsSection}>
                                <div className={styles.statsGrid}>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>BME680</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'air_quality', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].air_quality) ? bmeData[bmeData.length - 1].air_quality : null, false)}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'air_quality', null, true)}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'air_quality', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].air_quality) ? bmeData[bmeData.length - 1].air_quality : null, false)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].air_quality) ? bmeData[bmeData.length - 1].air_quality.toFixed(1) : '—'}%</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'air_quality', null, true)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData.reduce((acc, curr) => acc + (curr.air_quality || 0), 0) / bmeData.length) ? (bmeData.reduce((acc, curr) => acc + (curr.air_quality || 0), 0) / bmeData.length).toFixed(1) : '—'}%</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{bmeData.length > 0 && !isNaN(Math.min(...bmeData.map(reading => reading.air_quality || Infinity))) ? Math.min(...bmeData.filter(reading => !isNaN(reading.air_quality)).map(reading => reading.air_quality)).toFixed(1) : '—'}%</span>
                                                <span>{bmeData.length > 0 && !isNaN(Math.max(...bmeData.map(reading => reading.air_quality || 0))) ? Math.max(...bmeData.filter(reading => !isNaN(reading.air_quality)).map(reading => reading.air_quality)).toFixed(1) : '—'}%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>Quality</div>
                                        <div className={styles.statValue}>
                                            {bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].air_quality) ? (
                                                parseFloat(bmeData[bmeData.length - 1].air_quality) > 80 ? 'Excellent' :
                                                parseFloat(bmeData[bmeData.length - 1].air_quality) > 60 ? 'Good' :
                                                parseFloat(bmeData[bmeData.length - 1].air_quality) > 40 ? 'Fair' :
                                                parseFloat(bmeData[bmeData.length - 1].air_quality) > 20 ? 'Poor' : 'Bad'
                                            ) : '—'}
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                
                        {/* Gas Resistance Card */}
                        <div className={styles.measurementCard}>
                            <div className={styles.cardHeader}>
                                <h2 className={styles.cardTitle}>
                                    Gas Resistance
                                </h2>
                            </div>
                            <div className={styles.graphSection}>
                                    <div className={styles.graph}>
                                    <Line options={getChartOptions('gas_resistance')} data={gasResistanceChartData} />
                                </div>
                            </div>
                            <div className={styles.statsSection}>
                                <div className={styles.statsGrid}>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>BME680</div>
                                        <div className={styles.statProgress}>
                                            <div className={`${styles.progressIndicator} ${styles.current}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'gas_resistance', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].gas_resistance) ? bmeData[bmeData.length - 1].gas_resistance : null, false)}%` 
                                            }}></div>
                                            <div className={`${styles.progressIndicator} ${styles.average}`} style={{ 
                                                left: `${getFilteredProgressWidth(bmeData, 'gas_resistance', null, true)}%` 
                                            }}></div>
                                            <div className={styles.indicatorLabels}>
                                                <span className={`${styles.indicatorLabel} ${styles.current}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'gas_resistance', bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].gas_resistance) ? bmeData[bmeData.length - 1].gas_resistance : null, false)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData[bmeData.length - 1].gas_resistance) ? bmeData[bmeData.length - 1].gas_resistance.toFixed(1) : '—'} Ω</span>
                                                <span className={`${styles.indicatorLabel} ${styles.average}`} style={{ 
                                                    left: `${getFilteredProgressWidth(bmeData, 'gas_resistance', null, true)}%` 
                                                }}>{bmeData.length > 0 && !isNaN(bmeData.reduce((acc, curr) => acc + (curr.gas_resistance || 0), 0) / bmeData.length) ? (bmeData.reduce((acc, curr) => acc + (curr.gas_resistance || 0), 0) / bmeData.length).toFixed(1) : '—'} Ω</span>
                                            </div>
                                            <div className={styles.minMaxLabels}>
                                                <span>{bmeData.length > 0 && !isNaN(Math.min(...bmeData.map(reading => reading.gas_resistance || Infinity))) ? Math.min(...bmeData.filter(reading => !isNaN(reading.gas_resistance)).map(reading => reading.gas_resistance)).toFixed(1) : '—'} Ω</span>
                                                <span>{bmeData.length > 0 && !isNaN(Math.max(...bmeData.map(reading => reading.gas_resistance || 0))) ? Math.max(...bmeData.filter(reading => !isNaN(reading.gas_resistance)).map(reading => reading.gas_resistance)).toFixed(1) : '—'} Ω</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.statItem}>
                                        <div className={styles.statLabel}>Trend</div>
                                        <div className={styles.statValue}>
                                            {getReadingTrend(bmeData, 'gas_resistance') === 'rising' ? 'Rising' : 
                                             getReadingTrend(bmeData, 'gas_resistance') === 'falling' ? 'Falling' : 'Stable'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Temperature; 