import React from 'react';
import { useState, useRef } from 'react';
import logo from './logo.svg';
import close from './assets/close_white.png';
import { motion } from 'framer-motion';

import './App.css';
import './styles.css'
import { Gradient } from 'whatamesh'
import {useEffect} from 'react'
import generic from './projects/TemplatePage'
import pot from './projects/pot/pubsOnTap'
import fluentree from './projects/fluentree/Fluentree'

import vibracelet from './projects/vibracelet/vibracelet'
import linkedin from './assets/linkedin_w.png';
import liam_image from './assets/Liam.jpg';
import email_w from './assets/email_w.png';
import github from './assets/github_w.png';
import whatsapp from './assets/whatsapp_w.png';
import liamImg from './assets/liam.png';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Temperature from './pages/Temperature';
import giffgaff from './projects/mih/giffgaff';
import pitchside from './projects/pitchside/pitchside';
import summary from './projects/summary/summary';

function App() {
  const [projects, setProjects] = useState([pot, fluentree, vibracelet])
  const [projectsHidden, setProjectsHidden] = useState(projects.map(p => {return {project: p, isHidden: true}}))
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [message, setMessage] = useState("this is the default message");
  const [email, setEmail] = useState("jeremycolfer03@gmail.com");
  const [name, setName] = useState("default name");
  const emailInputRef = useRef(null);

  const enableMessageSend = true;

  const API_BASE = "https://cssfantasyapi.onrender.com";
  // const API_BASE = "http://localhost:3001";

  // Update the skills array
  const skills = [
    { name: "User-Centred Design", icon: "👥" },
    { name: "Product Development", icon: "🚀" },
    { name: "Business Strategy", icon: "📈" },
    { name: "Problem Solving", icon: "🔍" }
  ];

  // Add ref for the about container
  const aboutContainerRef = useRef(null);
  
  // Update initial positions with larger radius
  const [bubblePositions, setBubblePositions] = useState(
    skills.map((_, index) => ({
        angle: (index * 2 * Math.PI) / skills.length,
        radius: 250,  // Increased from 150 to 250
        speed: 0.002,
        radiusOffset: Math.random() * 20,
        verticalOffset: Math.random() * 20,
        phaseOffset: Math.random() * Math.PI * 2
    }))
);

  // Add state for container bounds
  const [containerBounds, setContainerBounds] = useState({ width: 0, height: 0 });

  // Add state to track if about page is visible
  const [isAboutVisible, setIsAboutVisible] = useState(false);

  // Add state for skills visibility
  const [areSkillsVisible, setAreSkillsVisible] = useState(false);

  // Add state to track popped bubbles
  const [poppedBubbles, setPoppedBubbles] = useState(new Set());

  // Add state to track if about page is closing
  const [isAboutClosing, setIsAboutClosing] = useState(false);

  // Update keyboard listener to reset popped bubbles
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key.toLowerCase() === 's') {
        setAreSkillsVisible(prev => !prev);
        setPoppedBubbles(new Set()); // Reset popped bubbles when toggling visibility
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Function to handle about page visibility
  const showAboutPage = () => {
    const aboutPage = document.getElementById("about-page");
    aboutPage.classList.remove("hidden");
    setIsAboutVisible(true);
    setTimeout(() => {
        setIsAboutClosing(false);
    }, 10);
  };

  const hideAboutPage = () => {
    document.getElementById("about-page").classList.add("hidden");
    setIsAboutVisible(false);
  };

  // Initialize bubble positions after container is mounted
  useEffect(() => {
    if (aboutContainerRef.current) {
      const container = aboutContainerRef.current;
      const bounds = container.getBoundingClientRect();
      
      // Calculate center position
      const centerX = bounds.width / 2;
      const centerY = bounds.height / 2;
      
      setBubblePositions(prevPositions => 
        prevPositions.map(pos => ({
          ...pos,
          // Start in a random position near the center
          x: centerX + (Math.random() - 0.5) * 200,  // ±100px from center
          y: centerY + (Math.random() - 0.5) * 200,  // ±100px from center
          // Slower, more gentle movement
          speedX: (Math.random() - 0.5) * 1,  // Reduced speed
          speedY: (Math.random() - 0.5) * 1   // Reduced speed
        }))
      );
    }
  }, []);

  // Update the animation effect
  useEffect(() => {
    if (!isAboutVisible || !aboutContainerRef.current) return;

    const updateBounds = () => {
        const bounds = aboutContainerRef.current.getBoundingClientRect();
        setContainerBounds(bounds);
    };

    updateBounds();
    window.addEventListener('resize', updateBounds);

    let animationFrameId;
    const animate = () => {
        setBubblePositions(prevPositions => 
            prevPositions.map(pos => ({
                ...pos,
                angle: pos.angle + pos.speed,
                currentRadius: pos.radius + Math.sin(Date.now() * 0.001 + pos.phaseOffset) * pos.radiusOffset,
                verticalShift: Math.cos(Date.now() * 0.0005 + pos.phaseOffset) * pos.verticalOffset
            }))
        );
        animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
        cancelAnimationFrame(animationFrameId);
        window.removeEventListener('resize', updateBounds);
    };
}, [isAboutVisible]);

  //API call for sening a message
  const sendMessage = async() => {
    const messageContent = document.getElementById("message-content").value;
    const messageName = document.getElementById("message-name").value;
    const messageReturnEmail = emailInputRef.current.value;

    console.log(emailInputRef.current.classList);


    const isValidEmailAddress = 
      matchesEmailRegex(messageReturnEmail) && messageReturnEmail.length >= 3;

    console.log(isValidEmailAddress);

    if(!isValidEmailAddress || messageName.length <= 0 || messageContent.length <= 0){
      window.alert("make sure you fill out each section before sending me a message");
      emailInputRef.current.classList.add("validityChecked");
      document.getElementById("message-name").classList.add("validityChecked");
      document.getElementById("message-content").classList.add("validityChecked")
    }


    if(enableMessageSend){
      const response  = await fetch(API_BASE+"/admin/email", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
          "for": "liam",
          // "for": "jeremy",
          "message": messageContent,
          "email": messageReturnEmail,
          "name": messageName
        })
    }).then(res => res.json()).then(res => {
        console.log(res.message);
        if(!res.error){
          handleSuccessfulMessage();
        }
        else{
          handleUnsuccessfulMessage();
        }
    }).catch(e => console.log(e));
  }
  else{
    window.alert("messaging currently unavailable. Apologies.")
  }
}

  useEffect(() => {
    // Only run gradient effect on home page
    if (window.location.pathname === '/') {
      const gradient = new Gradient();
      gradient.initGradient('#gradient-canvas');
    }
  }, []);

  useEffect(() => {
    // Only run project open effect on home page
    if (window.location.pathname === '/') {
      if(isProjectOpen) document.getElementById("projects-page").classList.add("project-open");
      else document.getElementById("projects-page").classList.remove("project-open");
    }
  }, [isProjectOpen]);

  useEffect(() => {
    // Only set viewport height if we're on home page
    if (window.location.pathname === '/') {
      console.log(window.innerHeight);
      document.documentElement.setAttribute("style", `--viewport-height: ${(window.innerHeight).toString()}px`);
    }
  }, []);

  const matchesEmailRegex = (emailStr) => {
    return  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    .test(emailStr)
  }

  //runs after receiving confirmation that email has been sent
  const handleSuccessfulMessage = () => {
    window.alert("Your message has been received, thank you!")
  }

    //runs when email sending has caused an error
    const handleUnsuccessfulMessage = () => {
      window.alert("Your message couldn't be received please try again")
    }

  // Add click handler
  const handleBubblePop = (skillName) => {
    setPoppedBubbles(prev => new Set([...prev, skillName]));
  };

  // Add back the image hover handlers
  const handleImageHover = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;
    
    const rotateY = ((xPercent - 50) / 50) * 20;
    const rotateX = -((yPercent - 50) / 50) * 20;
    
    container.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1.05, 1.05, 1.05)`;
  };

  const handleImageLeave = (e) => {
    e.currentTarget.style.transform = 'perspective(1000px) rotateY(0deg) rotateX(0deg) scale3d(1, 1, 1)';
  };

  const handleAboutClose = () => {
    setIsAboutClosing(true);
    setTimeout(() => {
        document.getElementById("about-page").classList.add("hidden");
        setIsAboutClosing(false);
        setIsAboutVisible(false);
    }, 200);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div onClick={() => {}}>

            <div className="gradient-container">
                <canvas id="gradient-canvas" data-transition-in/>
            </div>

            <div className="noise"></div>

            <div className="grid-container">

              <div className="name">
                <img className="liam_image" src={liamImg} alt="Liam Jones" />
              </div>

              <div className="bio">
                <p>Hi, I'm Liam, a final-year Computer Science with Innovation (MEng) student, blending technical expertise with a passion for design. I thrive in collaborative, Agile environments, where I can apply my skills to tackle complex problems and bring creative solutions to life.</p>
              </div>

              <div className="buttons">
                <button type="button" onClick={showAboutPage}>About</button>
                <button type="button"
                  onClick = {() => document.getElementById("projects-page").classList.toggle("hidden")}
                >Projects</button>
                <button type="button" onClick={() => document.getElementById("contact-form").classList.remove("hidden")}>Contact</button>
              </div>
            </div>

            <div id="about-page" 
                className={`about-page hidden ${isAboutClosing ? "closing" : ""}`}
            >
              <div className="about-container" ref={aboutContainerRef}>
                <button className="about-close-button"
                    onClick={handleAboutClose}
                >
                  <img className="close-button" src={close} alt="close window icon" />
                </button>

                <div className="skills-overview">
                  {skills.map((skill, index) => (
                    <motion.div 
                      key={skill.name}
                      className={`skill-bubble ${areSkillsVisible ? 'visible' : ''} ${poppedBubbles.has(skill.name) ? 'popping' : ''}`}
                      style={{
                        left: `${(window.innerWidth/2 - 150) + Math.cos(bubblePositions[index]?.angle || 0) * (bubblePositions[index]?.currentRadius || 250)}px`,
                        top: `${window.innerHeight/2 + Math.sin(bubblePositions[index]?.angle || 0) * (bubblePositions[index]?.currentRadius || 250)}px`,
                        '--delay': `${index * 0.1}s`,
                        display: poppedBubbles.has(skill.name) ? 'none' : 'flex',
                        position: 'fixed',
                        transform: 'translate(-50%, -50%)'
                      }}
                      onClick={() => handleBubblePop(skill.name)}
                    >
                      <span className="skill-icon">{skill.icon}</span>
                      {skill.name}
                    </motion.div>
                  ))}
                </div>
                
                <div className="about-content">
                  <div 
                    className="about-image-container"
                    onMouseMove={handleImageHover}
                    onMouseLeave={handleImageLeave}
                  >
                    <img className="about_image" src={liam_image} alt='a picture of me' />
                  </div>
                  
                  <div className="about-para">
                  <h2 className="about-title">ABOUT ME</h2>
                    <motion.div className="para">
                      <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                      >
                        Hi, I'm Liam! I'm a Master's student specialising in Computer Science with Innovation at the University of Bristol. My passion lies in Design Thinking, Human-Computer Interaction, and Product Design, which fuel both my academic journey and professional goals.
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                      >
                        I offer a unique combination of creativity, determination, and a proven ability to deliver projects collaboratively in diverse team environments. My experience spans both large corporate settings—like my summer technology internship at HSBC—and small agile startups, where I co-founded and developed an app, Pubs on Tap.
                      </motion.p>
                      <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6 }}
                      >
                        I thrive on working on imaginative and exciting projects that merge technology with creativity. Take a look at some of my work, and feel free to reach out!
                      </motion.p>
                    </motion.div>
                    <div className="about-buttons-container">
                      <button id="btn-gra" class="btn-gra" onClick={() => {
                        document.getElementById("about-page").classList.add("hidden")
                        document.getElementById("projects-page").classList.toggle("hidden"); 
                                                         }}>Projects</button>
                      <button id="btn-gra" class="btn-gra" onClick={() => document.getElementById("contact-form").classList.remove("hidden")}>Contact</button>
                    </div>
                    </div>
                    <div className="about-buttons-container-mobile">
                      <button id="btn-gra" class="btn-gra" onClick={() => {
                        document.getElementById("about-page").classList.add("hidden")
                        document.getElementById("projects-page").classList.toggle("hidden"); 
                                                         }}>Projects</button>
                      <button id="btn-gra" class="btn-gra" onClick={() => document.getElementById("contact-form").classList.remove("hidden")}>Contact</button>
                    </div>
                  </div>
                



              </div>



            </div>

            <div id="contact-form"className="contact-form hidden">

                <div className="top">
                  <h2 className='contact-header'>CONTACT</h2>
                  <button className='contact-close-button'
                  onClick={() => document.getElementById("contact-form").classList.add("hidden")}
                  >
                    <img className="close-button" src={close} alt="close window icon" />
                  </button>
                </div>

                <div className="socials">

                <a href="https://www.linkedin.com/in/liam--jones" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="Open my Linkedin Profile" />
                </a>

                <a href="mailto:liam@liamjones.io" target="_blank" rel="noopener noreferrer">
                  <img src={email_w} alt="Send me an Email" />
                </a>

                <a href="https://github.com/liam-jones-2002" target="_blank" rel="noopener noreferrer">
                  <img src={github} alt="Take a look at my Github" />
                </a>

                <a href="https://wa.me/7519075696" target="_blank" rel="noopener noreferrer">
                  <img src={whatsapp} alt="Message me on Whatsapp" />
                </a>

                

                </div>

                  <div className="form-container name-container">
                    <label htmlFor="message-name">Your name</label>
                    <input id="message-name" type="text" onChange={(e) => {
                      if(e.target.value.length > 0) e.target.classList.add("valid");
                      else(e.target.classList.remove("valid"))
                    }}/>
                  </div>

                  <div className="form-container content-container">
                    <label htmlFor="message-content">Your message</label>
                    <textarea id="message-content" className="message-content" type="text" onChange={(e) => {
                      if(e.target.value.length > 0) e.target.classList.add("valid");
                      else(e.target.classList.remove("valid"))
                    }}/>
                  </div>

                  <div className="form-container return-email-container">
                    <label htmlFor="message-return-email">Your email address</label>
                    <input ref={emailInputRef} id="message-return-email" type="email" onChange={(e) => {
                      if(e.target.value.length > 0 && matchesEmailRegex(e.target.value)) e.target.classList.add("valid");
                      else(e.target.classList.remove("valid"))
                    }} />
                  </div>


                {/* <button className='send-button-new'>New Send Button</button> */}

                {/* <a href="#" id="btn-gra" class="btn-gra">hover me</a> */}

                 <button id="btn-gra" class="btn-gra" onClick={async () => await sendMessage()}>Send Message</button>
            </div>

            <div className="projects-page hidden" id="projects-page">
                <div className="projects-backdrop">

                
                </div>
                <div className="top">
                  <h2 className='projects-header'>PROJECTS</h2>
                  <button className='projects-close-button'
                  onClick={() => document.getElementById("projects-page").classList.toggle("hidden")}
                  >
                    <img className="close-button" src={close} alt="close window icon" />
                  </button>
                </div>


                <div className="projects-container">
                  {/* <div className="project hidden-project"
                       onClick={e => e.target.classList.toggle("hidden-project")}> */}
                    {
                      projects.filter((q,i) => true).map((p, i) =>
                        <div className="project hidden-project"         
                             key={p.title + i} 
                             id={"project-" + i.toString()}

                             >

    <div className="project-info">
                      <h3 className="project-thumbnail-title">{p.title}</h3>
                      <div className="tags">
                        {p.tags.map( (t) => 
                          <p className='tag'>{t}</p>
                        )}
                      </div>
                    </div>

                    <div className="project-button">




                    <div className="overlay"
                    
                    onClick = {e => {; 
                    console.log("project toggled");
                    setProjectsHidden(projectsHidden.map((p,index) => {
                      setIsProjectOpen(true);
                      if (index == i){
                        const newP = p;
                        newP.isHidden = !p.isHidden;
                        return newP
                      }
                      else return p;  
                      }))
                    }}
                    >




                    </div>
                    <img className="project-thumbnail-image" 
                         src={p.img} 
                         alt={p.imgAltText} />

                    </div>

                    <p.TemplatePage 
                    hidden={projectsHidden[i].isHidden}
                    containerClasses={["expanded-project"]}
                    // closeFunction={{id: `project-${i.toString()}`, classToToggle:"hidden-project"}}
                    closeFunction = {e => setProjectsHidden(projectsHidden.map((p, index) => {
                      setIsProjectOpen(false);
                      if (index == i){
                        const newP = projects[i];
                        newP.isHidden = !projects[i].isHidden;
                        return newP;
                      }
                      else return p;  
                      }))}/>
                      
                    


                  </div>

                    )
                  }
                </div>
            </div>

          </div>
        } />
        <Route path="/temp" element={<Temperature />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
}

export default App;
